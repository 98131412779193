const handleClickMessagePredefini = function() {
    $('.custom-msg').on('click', function(e) {

        var button = $(this)
        e.preventDefault();
        var messageCode = $(this).data("message-code")

        var avisReglementsIds = ["23","31","48"]
        var messageId = button.attr("data-message-id")
        var remboursementId = button.attr("data-remboursement-id")
        var remboursementType = button.attr("data-remboursement-type")

        var agencesSansConfirmationIds = [6991]
        var agencesSansConfirmation = [100580,100514,100543,100421,42378,50331,42377,42484,42255,42216,42215,42378]


        if(avisReglementsIds.includes(messageId) &&  remboursementType != "1" && (!agencesSansConfirmation.includes(button.data("ref-agence")) && !agencesSansConfirmationIds.includes(button.data("agence-id")))) {
            $("#sinistre_messages_attributes_0_avis_reglement").val(true)
            $("#sinistre_messages_attributes_0_remboursement_id").val(remboursementId)
        } else {
            $("#sinistre_messages_attributes_0_avis_reglement").val(false)
            $("#sinistre_messages_attributes_0_remboursement_id").val("")
        }

        if(e.which != 3 && $(e.target).parents(".user-msg-context-menu ").length == 0) {
            const trix      = document.querySelector($(e.target).data('target'))
            trix.editor.setSelectedRange([0, 0])
            trix.editor.loadHTML('')
            var body = decodeURIComponent( $(e.target).data('body') )

            if(messageCode == "avis_reglement") {

                var montantFranchiseUnitaire = parseFloat($("#sinistre_montant_franchise_pax").val())
                var montantFranchise = parseFloat($("#sinistre_montant_franchise_pax").val()) * parseFloat($("#sinistre_pax_total_indemnises").val())
                var montantFrais = parseFloat($("#sinistre_montant_provision").val())
                var montantRemboursement = parseFloat($("#sinistre_montant_total_indemniser").val())

                body = body.replace(/(\[montant_frais\])/g, montantFrais + "€")
                body = body.replace(/(\[montant_franchise\])/g, montantFranchise + "€")
                body = body.replace(/(\[montant_franchise_unitaire\])/g, montantFranchiseUnitaire + "€")
                body = body.replace(/(\[montant_remboursement\])/g, montantRemboursement + "€")

            }

            if(messageCode == "explication_coupe") {

                var montantFraisAnnulation = parseFloat($("#sinistre_frais_attributes_0_bareme").val())
                var dateEffet = $("#info-date-effet-to-parse").data("date-effet")
                var montantFranchiseUnitaire = parseFloat($("#sinistre_montant_franchise_pax").val())
                var montantRemboursement = parseFloat($("#sinistre_montant_total_indemniser").val())

                body = body.replace(/(\[date_effet\])/g, dateEffet)
                body = body.replace(/(\[% frais annulation\])/g, montantFraisAnnulation)
                body = body.replace(/(\[montant_franchise_unitaire\])/g, montantFranchiseUnitaire + "€")
                body = body.replace(/(\[montant_remboursement\])/g, montantRemboursement + "€")


            }

            trix.editor.insertHTML(body)

            // Gestion du statut du message (interne ou externe)
            // Quand un message prédéfini est selectionné par défaut le message n'est plus privé.
            const notPrivateRadio = $( $(e.target).data('id') + '_private_false' )
            if (notPrivateRadio.length) {
                notPrivateRadio.prop('checked', true)
            }

            addFilesForBonsAchatsValables(e.target)
        }

    })
}

const addFilesForBonsAchatsValables = (elem) => {


    const typeMessage = $(elem).data('type'),
        template = $(elem).data('template');

    if (typeMessage != 'bon_achat') {
        $('#sinistre-special-event').html('')
        return false 
    }

    const url = '/api/sinistres/' + $(elem).data('sinistre-id') + '/bons_achats_valables_paths'
    fetch(url)
    .then(response => response.json())
    .then( (filepaths) => {
        const params = '?template=' + template,
            filepondContainer = $('#sinistre_messages_attributes_0_files')
            
        filepaths.forEach(filepath => filepondContainer.filepond('addFile', filepath + params))
    })

    $('#sinistre-special-event').html('<input type="hidden" name="sinistre[special_event]" value="bons_achats_a_emettre" />')
}

export default handleClickMessagePredefini;