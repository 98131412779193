document.addEventListener('turbolinks:load', () => {    

	var checkBoxesLength = $(".virement-checkbox").length
	$(".virement-checkbox").each(function() {
		$(this).on("click", function() {
			if(!this.checked) {
				checkBoxesLength = checkBoxesLength - 1
			} else {
				checkBoxesLength = checkBoxesLength + 1
			}
		})
	})

	const form = $("#virement-export-form")
	const formSubmit = $("#export-virements-submit")
	const formAlert = $("#virements-export-alert")

	formSubmit.on("click", function(e) {
		e.preventDefault()
		if(checkBoxesLength == 0) {
			formAlert.show()
		} else {
			formAlert.hide()
			form.submit()
		}
	})


})